export const dataReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEALERSHIPS':
      return { ...state, dealerships: action.dealerships }
    case 'SET_DEALERSHIP':
      return { ...state, dealership: action.dealership }
    case 'SET_MODELS':
      return { ...state, models: action.models }
    case 'SET_SERVICES':
      return { ...state, services: action.services || [] }
    case 'SET_AVAILABILITY':
      return { ...state, availability: action.availability }
    case 'SET_INSURANCE_QUESTIONS':
      return { ...state, questions: action.questions }
    case 'SET_PAYMENT_METHODS':
      return { ...state, methods: action.methods }
    case 'SET_ERROR':
      return { ...state, error: action.error }
    default:
      return state
  }
}
