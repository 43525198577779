import React, { createContext, useReducer } from 'react'
import { node } from 'prop-types'

import { dataReducer } from '../../../reducer/data-reducer'

const DataContext = createContext()
const DataProvider = ({ children }) => {
  const initialState = {
    dealerships: [],
    models: [],
    services: { services: [], investigation: {} },
    availability: [],
    error: null
  }
  const [data, dispatch] = useReducer(dataReducer, initialState)
  return (
    <DataContext.Provider value={{ data, dispatch }}>
      {children}
    </DataContext.Provider>
  )
}

DataProvider.propTypes = {
  children: node.isRequired
}

export { DataContext, DataProvider }
