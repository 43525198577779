import React, { createContext, useReducer, useEffect } from 'react'
import { node } from 'prop-types'

import { formReducer } from '../../../reducer/form-reducer'
import { parseJSON, isValid } from 'date-fns'

const FormContext = createContext()
const FormProvider = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false)
  const localState = process.browser
    ? JSON.parse(sessionStorage.getItem('formData'))
    : undefined

  if (localState) {
    localState.booking.month = parseJSON(localState.booking.month)
    if (!isValid(localState.booking.month)) {
      localState.booking.month = null
    }

    localState.booking.date = parseJSON(localState.booking.date)
    if (!isValid(localState.booking.date)) {
      localState.booking.date = null
    }
  }

  const initialState = {
    dealership: {},
    vehicle: { regNumber: '', model: '', name: '', color: '' },
    services: {
      selected: [],
      total: 0,
      investigation: { text: '', code: '', allowedTime: 0 }
    },
    booking: { facility: 'none' },
    details: {}
  }
  const [form, dispatch] = useReducer(formReducer, localState || initialState)

  useEffect(() => {
    setHasMounted(true)
    sessionStorage.setItem('formData', JSON.stringify(form))
  }, [form])

  if (!hasMounted) return null
  return (
    <FormContext.Provider value={{ form, dispatch }}>
      {children}
    </FormContext.Provider>
  )
}

FormProvider.propTypes = {
  children: node.isRequired
}

export { FormContext, FormProvider }
