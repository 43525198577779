import React from 'react'
import { func, object } from 'prop-types'
import Head from 'next/head'
import App from 'next/app'

import '@/asset/scss/base.scss'
// import '../src/asset/scss/email/email.scss'

import * as Sentry from '@sentry/react'

import { DataProvider } from '../src/component/Context/DataContext'
import { FormProvider } from '../src/component/Context/FormContext'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-57KSCDX'
}

Sentry.init({
  dsn: 'https://d99a45ae14434ae59a49a59bc0b571c8@o36550.ingest.sentry.io/5305680',
  ignoreErrors: [
    // Microsoft SafeLink Crawler https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Object Not Found Matching Id:'
  ]
})

class MyApp extends App {
  componentDidMount() {
    TagManager.initialize(tagManagerArgs)
  }

  render() {
    const { Component, pageProps } = this.props
    return (
      <>
        <Head />
        <DataProvider>
          <FormProvider>
            <Component {...pageProps} />
          </FormProvider>
        </DataProvider>
      </>
    )
  }
}

MyApp.propTypes = {
  Component: func,
  pageProps: object,
  router: object
}

export default MyApp
