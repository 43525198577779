export const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEALERSHIP':
      return { ...state, dealership: action.dealership }
    case 'SET_VEHICLE': {
      if (action.vehicle === null)
        return { ...state, vehicle: { regNumber: state.vehicle.regNumber } }
      const vehicle = Object.assign({}, state.vehicle, action.vehicle)
      return { ...state, vehicle }
    }
    case 'SET_SERVICES': {
      const services = Object.assign(
        {},
        state.services.services,
        action.services
      )
      return {
        ...state,
        services: { ...state.services, services: services || [] }
      }
    }
    case 'RESET_SELECTED_SERVICES': {
      return {
        ...state,
        services: { ...state.services, selected: [] }
      }
    }
    case 'SET_TOTAL':
      return { ...state, services: { ...state.services, total: action.total } }
    case 'SET_INVESTIGATION':
      return {
        ...state,
        services: {
          ...state.services,
          investigation: {
            text: action.text,
            code: action.code,
            allowedTime: action.allowedTime
          }
        }
      }
    case 'SET_BOOKING':
      return { ...state, booking: { ...state.booking, ...action.booking } }
    case 'SET_DETAILS':
      return { ...state, details: { ...state.details, ...action.details } }
    default:
      return state
  }
}
